import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';
import { StyledContainer } from '../../style/Global';
import { ReactComponent as BrandIcon } from '../../assets/logo.svg';




const StyledBrandIcon = styled(BrandIcon)`
`;


const StyledNavbar = styled(Navbar)`

box-shadow: 1px 4px 55px 5px rgba(0,0,0,0.69);
-webkit-box-shadow: 1px 4px 55px 5px rgba(0,0,0,0.69);
-moz-box-shadow: 1px 4px 55px 5px rgba(0,0,0,0.69);

  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;

  .animation{
    @media screen and (max-width: 995px) {
  
  justify-content: center !important;

}

  }
  
.navbar-light .navbar-nav .nav-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
  
  }
  .onHover {
      padding-bottom: 8px;
      color: black; 
       &:hover {
        font-weight: bold;
          border-bottom: 4px solid #FFD21E;
      }
      @media screen and (max-width: 468px) {
        &:hover {
          border-bottom: 4px solid #ffffff;
        }
      }
  }
.navbar {

  z-index:2;

justify-content: center;

}
.close-navbar-toggler {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:1;
  cursor:pointer;
}
.close-navbar-toggler.collapsed {
  z-index:-1;
}
.nav-item > .active {
    border-bottom: none;
}

a {
    color: none !important;
    text-decoration: none !important;
}

a:hover {
    color: none !important;
    text-decoration: none !important;
}
.active {
    color: #000000;
    font-weight: bold;
    border-bottom: 4px solid #FFD21E;
    padding-bottom: 0.5rem;
    border-radius: 5%;
  }

.active:hover {
    color: #000000;
  }



  .navbar-toggler{
    position: absolute;
    right:15px;
    top: 0;
    &.nav-body-toggle{
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1;

          cursor: initial;
          
          span {
            display: none;
          }
  
          &.collapsed {
            z-index: -1;
            pointer-events: none;
          }
    }
  }


`;
const StyledNavbarToggle = styled(Navbar.Toggle)`
  
  &.collapsed {
    z-index: -1;
    pointer-events: none;

    span {
      display: none;
    }
  }

`;



const NavStyledContainer = styled(StyledContainer)`
position: relative;
  padding: 0.75rem 8.063rem;
  max-width: 1920px;
  /* justify-content: center !important; */
  .me-auto {
    margin-left: auto !important;
  }


  @media screen and (max-width: 1200px) {
    padding: 0.75rem 1.5rem;
    
  }
    @media screen and (max-width: 480px) {
        padding: 0.1rem 0.8rem;
}
  `;

const StyledButton = styled(Button)`
  background-color: #1F3E90;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.7rem 1.5rem;
  border-radius: 0.625rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  outline: none;
  
  &:hover {
    background-color: #162f75;
  }
  
  &:active {
    background-color: #162f75;
  }
  &:focus {
    outline: none;
     background-color: #162f75;
     box-shadow: none;
  }
  &:blur {
    outline: none;
     background-color: #162f75;
  }
 
`;


const StyledNavbarCollapse = styled(Navbar.Collapse)`
  z-index: 1;
`;
const StyledNavbarLink = styled(Nav.Link)`
  color: #000000;
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  border-bottom: 4px solid #FFF;
  padding-bottom: 0.5rem;
  position: relative;
  transition: all 0.3s ease-in-out;
 
  
    @media screen and (max-width: 480px) {
        margin-left: 0.1rem;
    }

    @media screen and (max-width: 1200px) {
        margin-left: 0rem;
    }
    `;



export { StyledNavbar,StyledBrandIcon, StyledNavbarToggle, NavStyledContainer, StyledButton, StyledNavbarLink, StyledNavbarCollapse };