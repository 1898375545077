import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";


export const ContainerRow = styled(Container)``;

export const CustomRow = styled(Row)`

margin: 0;
-bs-gutter-x: 0 !important;
`;

export const CustomCol = styled(Col)``;

export const Wrapper = styled("div")`
width: 100%;
background-color: #1F3E90;
`;
export const FooterText = styled("p")`
color: white;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0.5px;
text-align: center;
margin:auto 3px;

b{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-align: left;
}

`; 