import React from 'react'
import { ContainerRow, CustomCol, CustomRow, FooterText, Wrapper } from './styles'

const Footer = () => {
  return (
    <Wrapper>
        <ContainerRow>
            <CustomRow >
                <CustomCol>
                    <FooterText>©2022 <b>ABS Polymer.</b> All Rights Reserved.</FooterText>
                </CustomCol>
            </CustomRow>
        </ContainerRow>
    </Wrapper>
  )
}

export default Footer