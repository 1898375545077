import {
  StyledNavbar,
  NavStyledContainer,
  StyledButton,
  StyledNavbarLink,
  StyledNavbarCollapse,
  StyledBrandIcon,
} from "./styles";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Turn as Hamburger } from "hamburger-react";
import { Link, animateScroll as scroll } from "react-scroll";
import { useState, useEffect, Fragment } from "react";
import { useScroll } from "framer-motion";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [animation, setanimation] = useState(false);

  const HandleClose = () => {
    setIsOpen(!isOpen);
  };

  const { scrollY } = useScroll();

  useEffect(() => {
    return scrollY.onChange((latest: any) => {
      if (latest > 600) {
        setanimation(true);
      } else {
        setanimation(false);
      }
    });
  }, [scrollY]);

  const Collapse = window.innerWidth > 991 ? Fragment : StyledNavbarCollapse;
  return (
    <>
      <StyledNavbar
        bg="#fff"
        expand="lg"
        fixed="top"
        className="navbar"
        expanded={isOpen}
      >
        <Navbar.Toggle
          className="nav-body-toggle"
          aria-controls="basic-navbar-nav"
          onClick={HandleClose}
        />
        <NavStyledContainer fluid className={animation ? "animation" : ""}>
          <Navbar.Brand>
            <StyledBrandIcon onClick={() => scroll.scrollToTop()} />
          </Navbar.Brand>
          <Navbar.Toggle onClick={HandleClose}>
            <Hamburger toggled={isOpen} />
          </Navbar.Toggle>

          <Collapse id="navbarScroll">
            <Nav className="me-auto" navbarScroll>
              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="home"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-70}
                  duration={800}
                >
                  Home
                </Link>
              </StyledNavbarLink>

              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="about"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-70}
                  duration={800}
                >
                  About Us
                </Link>
              </StyledNavbarLink>

              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="suppliers"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-70}
                  duration={800}
                >
                  Suppliers
                </Link>
              </StyledNavbarLink>

              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="products"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-60}
                  duration={800}
                >
                  Products
                </Link>
              </StyledNavbarLink>

              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="services"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-60}
                  duration={800}
                >
                  Services
                </Link>
              </StyledNavbarLink>

              <StyledNavbarLink>
                <Link
                  className="onHover"
                  onClick={HandleClose}
                  to="industries"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={800}
                >
                  Industries
                </Link>
              </StyledNavbarLink>
            </Nav>

            <StyledNavbarLink>
              <Link
                className="onHover"
                onClick={HandleClose}
                to="contact"
                spy={true}
                activeClass="null"
                smooth={true}
                offset={350}
                duration={800}
              >
                contact us
              </Link>
            </StyledNavbarLink>
          </Collapse>
        </NavStyledContainer>
      </StyledNavbar>
    </>
  );
};

export default Header;
