import { lazy } from 'react';
import Header from './components/Header';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from "react-router-dom";
import Footer from './components/Footer';
const Home = lazy(() => import("./views/Home"));


const App: React.FC = () => {
  const history = createBrowserHistory();
  

  return (
    <>
      <Header />
    <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <Footer/>
    </>
  );
}

export default App;
